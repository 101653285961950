<template>
  <header
    id="MainHeader"
    class="fixed top-0 left-0 right-0 z-30 pointer-events-none"
  >
    <div
      class="flex items-center justify-between w-full px-5 pt-10 pointer-events-none md:px-10"
    >
      <router-link to="/Home" class="cursor-pointer pointer-events-auto">
        <img
          width="192"
          height="50"
          class="w-32 md:w-48"
          src="/img/Logo.webp"
          alt="SavageBull 野蠻公牛"
        />
      </router-link>

      <div class="flex items-center">
        <button
          @click="$store.commit('SetLanguageDialog', true)"
          class="flex items-center py-1 pl-3 pr-1 mr-2 text-xs font-bold text-white transition-colors duration-300 bg-black border pointer-events-auto hover:bg-main-color hover:border-black border-main-color"
        >
          中文
          <arrow_drop_down class="ml-1 text-xl" />
        </button>
        <button
          class="pointer-events-auto"
          @mouseenter="button_hover = true"
          @mouseleave="button_hover = false"
          @click="$emit('open-menu')"
        >
          <svg
            width="45"
            height="10"
            viewBox="0 0 45 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="transition-transform duration-300 origin-right transform"
            :class="button_hover ? 'scale-x-125' : ''"
          >
            <path
              d="M16.41 7.96001L17.055 7.42001H43.59H45V9.37001H43.05H42.24H15L16.41 7.96001Z"
              fill="black"
              class="duration-300 transition-color"
              :class="button_hover ? ' opacity-50' : 'opacity-100'"
            />
            <path
              d="M22.1251 1H43.4851H44.5501H45.0001V2.95H43.9051H43.0501H42.6001H41.5801H20.1001L22.1251 1Z"
              fill="black"
              class="duration-300 transition-color"
              :class="button_hover ? ' opacity-50' : 'opacity-100'"
            />
          </svg>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import arrow_drop_down from '@/components/svg/arrow_drop_down';
export default {
  name: 'MainHeader',
  components: {
    arrow_drop_down,
  },
  data() {
    return {
      menu_open: false,
      button_hover: false,
      menu_list: [
        {
          title: '首頁',
          sub_title: 'HOME PAGE',
          link: '/',
        },
        {
          title: '關於我們',
          sub_title: 'ABOUT US',
          link: '/',
        },
        {
          title: '產品列表',
          sub_title: 'PRODUCTS',
          link: '/',
        },
        {
          title: '下載專區',
          sub_title: 'DOWNLOADS',
          link: '/',
        },
        {
          title: '客製化選色',
          sub_title: 'CUSTOMIZE',
          link: '/',
        },
        {
          title: '聯絡我們',
          sub_title: 'CONTACT US',
          link: '/',
        },
      ],
    };
  },
};
</script>
