<template>
  <section
    id="HomeBrand"
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl pt-40 pb-48 mx-auto"
  >
    <div class="relative z-10 px-10 lg:px-40 sm:px-20">
      <h2
        data-title
        class="title font-audiowide md:text-8xl sm:text-[80px] text-[55px] leading-tight text-white sm:mb-9 mb-4"
      >
        OUR BRAND
      </h2>
      <title_label text="系列品牌" :light="false" />
    </div>

    <div
      class="relative z-10 flex flex-wrap items-stretch justify-center w-full px-0 mt-32 main_content lg:px-40 sm:px-10"
    >
      <div
        class="relative flex-wrap items-center justify-center hidden w-1/2 pr-20 lg:flex"
      >
        <card
          @mouseenter="hover = 1"
          @mouseleave="hover = -1"
          :title="brand_list[1].title"
          :sub_title="brand_list[1].sub_title"
          :bg_image="brand_list[1].bg_image"
          :logo_image="brand_list[1].logo_image"
          number="02"
          :link="brand_list[1].link"
          :hover="hover == 1"
        />

        <svg
          class="absolute w-8 right-10 top-44"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <rect class="cls-1" fill="#f6f6f6" y="6" width="12" height="12" />
          <rect
            class="opacity-60"
            fill="#f6f6f6"
            x="12"
            y="18"
            width="12"
            height="12"
          />
          <rect class="opacity-40" fill="#f6f6f6" x="24" width="6" height="6" />
        </svg>

        <img
          class="absolute bottom-0 z-0 w-2/3"
          src="/img/home/web_homepage_brand_bg_5.webp"
        />

        <img
          class="absolute z-0 top-2/3 -left-20 w-[200px] transform -translate-y-10"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
      </div>

      <div class="relative w-full lg:w-1/2">
        <card
          class="w-11/12 ml-0 mb-44 lg:h-auto sm:h-80 h-60 lg:w-full sm:w-3/4"
          @mouseenter="hover = 0"
          @mouseleave="hover = -1"
          :title="brand_list[0].title"
          :sub_title="brand_list[0].sub_title"
          :bg_image="brand_list[0].bg_image"
          :logo_image="brand_list[0].logo_image"
          number="01"
          :link="brand_list[0].link"
          :hover="hover == 0"
        />

        <card
          class="block w-11/12 ml-auto mr-0 lg:hidden mb-44 lg:h-auto sm:h-80 h-60 lg:w-full sm:w-3/4"
          @mouseenter="hover = 1"
          @mouseleave="hover = -1"
          :title="brand_list[1].title"
          :sub_title="brand_list[1].sub_title"
          :bg_image="brand_list[1].bg_image"
          :logo_image="brand_list[1].logo_image"
          number="02"
          :link="brand_list[1].link"
          :hover="hover == 1"
        />

        <card
          class="w-11/12 ml-0 lg:h-auto sm:h-80 h-60 lg:w-full sm:w-3/4"
          @mouseenter="hover = 2"
          @mouseleave="hover = -2"
          :title="brand_list[2].title"
          :sub_title="brand_list[2].sub_title"
          :bg_image="brand_list[2].bg_image"
          :logo_image="brand_list[2].logo_image"
          number="03"
          :link="brand_list[2].link"
          :hover="hover == 2"
        />

        <!-- 點點 -->
        <img
          class="absolute z-0 transform lg:top-1/2 lg:-right-40 lg:w-[200px] lg:-translate-y-1/2 sm:top-32 sm:-right-20 sm:w-auto w-80 -top-44 -right-20"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <img
          class="absolute z-0 block transform lg:hidden sm:top-1/2 sm:left-0 sm:-translate-y-1/3 w-80 top-44 left-5"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <img
          class="absolute z-0 block transform lg:hidden sm:-bottom-40 sm:right-0 sm:w-auto w-80 bottom-40 -right-10"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <!-- 點點 -->
        <!-- xl text -->
        <img
          class="absolute right-0 z-0 hidden w-16 transform lg:block top-1/2 translate-y-14"
          src="/img/home/web_homepage_brand_bg_6.webp"
        />
        <!-- xl text -->
        <!-- lg text -->
        <img
          class="absolute z-0 block transform lg:hidden sm:top-1/3 sm:left-0 sm:translate-y-20 top-64 right-20"
          src="/img/home/ipad_homepage_brand_bg_5.webp"
        />
        <img
          class="absolute z-0 block transform -translate-x-1/2 lg:hidden sm:bottom-20 sm:right-20 sm:translate-y-20 sm:-translate-x-0 bottom-48 left-1/2"
          src="/img/home/ipad_homepage_brand_bg_3.webp"
        />
        <!-- lg text -->
        <!-- 方塊 -->
        <svg
          class="absolute w-6 transform lg:w-6 lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:translate-y-12 sm:w-8 sm:top-96 sm:left-60 top-96 left-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <rect class="cls-1" fill="#f6f6f6" y="6" width="12" height="12" />
          <rect
            class="opacity-60"
            fill="#f6f6f6"
            x="12"
            y="18"
            width="12"
            height="12"
          />
          <rect class="opacity-40" fill="#f6f6f6" x="24" width="6" height="6" />
        </svg>

        <svg
          class="absolute w-8 transform rotate-180 -translate-x-1/2 lg:w-6 lg:-left-20 lg:-bottom-20 lg:rotate-0 sm:top-2/3 sm:right-20 sm:translate-x-0 -bottom-32 left-1/2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <rect class="cls-1" fill="#f6f6f6" y="6" width="12" height="12" />
          <rect
            class="opacity-60"
            fill="#f6f6f6"
            x="12"
            y="18"
            width="12"
            height="12"
          />
          <rect class="opacity-40" fill="#f6f6f6" x="24" width="6" height="6" />
        </svg>
        <!-- 方塊 -->
      </div>

      <div class="hidden lg:block">
        <img
          class="absolute z-0 w-1/3 transform -top-10 left-1/2 -translate-x-80"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <img
          class="absolute z-0 top-1/2 left-1/2 w-1/4 transform -translate-x-1/2 -translate-y-[10%]"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
      </div>
    </div>

    <div
      class="relative z-10 flex flex-wrap items-start justify-center w-full px-0 mt-20 main_content lg:px-40 sm:px-10"
    >
      <div
        class="relative flex-wrap items-center justify-center hidden w-1/2 pr-20 lg:flex"
      >
        <card
          @mouseenter="hover = 3"
          @mouseleave="hover = -1"
          :title="brand_list[3].title"
          :sub_title="brand_list[3].sub_title"
          :bg_image="brand_list[3].bg_image"
          :logo_image="brand_list[3].logo_image"
          number="04"
          :link="brand_list[3].link"
          :hover="hover == 3"
        />

        <svg
          class="absolute w-8 right-10 top-44"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <rect class="cls-1" fill="#f6f6f6" y="6" width="12" height="12" />
          <rect
            class="opacity-60"
            fill="#f6f6f6"
            x="12"
            y="18"
            width="12"
            height="12"
          />
          <rect class="opacity-40" fill="#f6f6f6" x="24" width="6" height="6" />
        </svg>

        <img
          class="absolute bottom-0 z-0 w-2/3"
          src="/img/home/web_homepage_brand_bg_5.webp"
        />

        <img
          class="absolute z-0 top-2/3 -left-20 w-[200px] transform -translate-y-10"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
      </div>

      <div class="relative w-full mt-32 lg:w-1/2">
        <card
          class="block w-11/12 ml-auto mr-0 lg:hidden mb-44 lg:h-auto sm:h-80 h-60 lg:w-full sm:w-3/4"
          @mouseenter="hover = 3"
          @mouseleave="hover = -1"
          :title="brand_list[3].title"
          :sub_title="brand_list[3].sub_title"
          :bg_image="brand_list[3].bg_image"
          :logo_image="brand_list[3].logo_image"
          number="04"
          :link="brand_list[3].link"
          :hover="hover == 3"
        />

        <card
          class="w-11/12 ml-0 lg:h-auto sm:h-80 h-60 lg:w-full sm:w-3/4"
          @mouseenter="hover = 4"
          @mouseleave="hover = -1"
          :title="brand_list[4].title"
          :sub_title="brand_list[4].sub_title"
          :bg_image="brand_list[4].bg_image"
          :logo_image="brand_list[4].logo_image"
          number="05"
          :link="brand_list[4].link"
          :hover="hover == 4"
        />

        <!-- 點點 -->
        <img
          class="absolute z-0 transform lg:top-1/2 lg:-right-40 lg:w-[200px] lg:-translate-y-1/2 sm:top-32 sm:-right-20 sm:w-auto w-80 -top-44 -right-20"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <img
          class="absolute z-0 block transform lg:hidden sm:top-1/2 sm:left-0 sm:-translate-y-1/3 w-80 top-44 left-5"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <img
          class="absolute z-0 block transform lg:hidden sm:-bottom-40 sm:right-0 sm:w-auto w-80 bottom-40 -right-10"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <!-- 點點 -->
        <!-- xl text -->
        <img
          class="absolute right-0 z-0 hidden w-16 transform lg:block top-1/2 translate-y-14"
          src="/img/home/web_homepage_brand_bg_6.webp"
        />
        <!-- xl text -->
        <!-- lg text -->
        <img
          class="absolute z-0 block transform lg:hidden sm:top-1/3 sm:left-0 sm:translate-y-20 top-64 right-20"
          src="/img/home/ipad_homepage_brand_bg_5.webp"
        />
        <img
          class="absolute z-0 block transform -translate-x-1/2 lg:hidden sm:bottom-20 sm:right-20 sm:translate-y-20 sm:-translate-x-0 bottom-48 left-1/2"
          src="/img/home/ipad_homepage_brand_bg_3.webp"
        />
        <!-- lg text -->
        <!-- 方塊 -->
        <svg
          class="absolute w-6 transform lg:w-6 lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:translate-y-12 sm:w-8 sm:top-96 sm:left-60 top-96 left-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <rect class="cls-1" fill="#f6f6f6" y="6" width="12" height="12" />
          <rect
            class="opacity-60"
            fill="#f6f6f6"
            x="12"
            y="18"
            width="12"
            height="12"
          />
          <rect class="opacity-40" fill="#f6f6f6" x="24" width="6" height="6" />
        </svg>

        <svg
          class="absolute w-8 transform rotate-180 -translate-x-1/2 lg:w-6 lg:-left-20 lg:-bottom-20 lg:rotate-0 sm:top-2/3 sm:right-20 sm:translate-x-0 -bottom-32 left-1/2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <rect class="cls-1" fill="#f6f6f6" y="6" width="12" height="12" />
          <rect
            class="opacity-60"
            fill="#f6f6f6"
            x="12"
            y="18"
            width="12"
            height="12"
          />
          <rect class="opacity-40" fill="#f6f6f6" x="24" width="6" height="6" />
        </svg>
        <!-- 方塊 -->
      </div>

      <div class="hidden lg:block">
        <img
          class="absolute z-0 w-1/3 transform -top-10 left-1/2 -translate-x-80"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
        <img
          class="absolute z-0 top-1/2 left-1/2 w-1/4 transform -translate-x-1/2 -translate-y-[10%]"
          src="/img/home/web_homepage_brand_bg_1.webp"
        />
      </div>
    </div>
  </section>
</template>

<script>
import title_label from "@/components/ui/title_label.vue";
import card from "@/components/home/brand_card/index.vue";
import { home_brand } from "@/gsap/home/brands";

export default {
  name: "HomeBrands",
  components: { title_label, card },
  data() {
    return {
      hover: -1,
      home_brand_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.home_brand_animation = new home_brand(this.$refs.MainContent);
    },
  },
  computed: {
    brand_list() {
      return this.$store.state.brand_list;
    },
  },
};
</script>
