<template>
  <div
    id="LanguageDialog"
    ref="MainContent"
    class="fixed top-0 left-full right-0 bottom-0 z-[99] bg-white bg-opacity-70 text-white opacity-0 flex items-center justify-center"
  >
    <div
      data-dialog-box
      class="w-[500px] max-w-[90%] overflow-hidden main_content relative opacity-0 transform scale-0"
    >
      <button
        data-dialog-background
        @mouseenter="hover_close_btn = true"
        @mouseleave="hover_close_btn = false"
        @click="Close()"
        class="bg_el absolute z-[11] top-3 right-3 flex items-center justify-center sm:w-[50px] sm:h-[50px] w-[40px] h-[40px] frame"
      >
        <span
          class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute top-0 left-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? 'translate-y-1 translate-x-1' : ''"
        ></span>
        <span
          class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute top-0 left-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? 'translate-x-1 translate-y-1' : ''"
        ></span>
        <span
          class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute top-0 right-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? 'translate-y-1 -translate-x-1' : ''"
        ></span>
        <span
          class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute top-0 right-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? 'translate-y-1 -translate-x-1' : ''"
        ></span>
        <close_icon class="text-lg text-white sm:text-2xl" />
        <span
          class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute bottom-0 left-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? '-translate-y-1 translate-x-1' : ''"
        ></span>
        <span
          class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute bottom-0 left-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? '-translate-y-1 translate-x-1' : ''"
        ></span>
        <span
          class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute bottom-0 right-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? '-translate-y-1 -translate-x-1' : ''"
        ></span>
        <span
          class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute bottom-0 right-0 z-[41] transform transition-transform duration-300"
          :class="hover_close_btn ? '-translate-y-1 -translate-x-1' : ''"
        ></span>
      </button>
      <span
        data-dialog-background
        class="absolute z-10 block text-xs text-white transform scale-75 rotate-90 bg_el font-audiowide sm:scale-100 sm:top-20 top-14 sm:-left-3 -left-6 sm:opacity-100 opacity-30"
        >SAVAGE BULL</span
      >

      <span
        data-dialog-background
        class="absolute z-10 block text-xl text-white transform scale-50 rotate-90 bg_el font-audiowide sm:scale-100 top-1/2 -right-16 sm:opacity-100 opacity-30"
        >SAVAGE BULL</span
      >

      <div
        data-dialog-msg
        class="absolute z-10 w-full max-h-full px-10 overflow-y-auto text-sm font-medium text-center transform -translate-x-1/2 -translate-y-1/2 content top-1/2 left-1/2 sm:text-lg"
      >
        <h3 class="text-2xl font-bold">選擇語言</h3>
        <p class="text-sm font-audiowide">SELECT LANGUAGE</p>
        <ol class="w-64 py-4 mx-auto">
          <li class="w-full mb-4">
            <button
              @click="ChooseLanguage('en')"
              class="relative w-full px-4 py-2 transition-colors duration-500 bg-white hover:bg-black bg-opacity-20 font-audiowide"
            >
              ENGLISH
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute top-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute top-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute top-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute top-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute bottom-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute bottom-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute bottom-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute bottom-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
            </button>
          </li>
          <li class="w-full">
            <button
              @click="ChooseLanguage('zh')"
              class="relative w-full px-4 py-2 transition-colors duration-500 bg-white hover:bg-black bg-opacity-20 font-audiowide"
            >
              繁體中文
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute top-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute top-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute top-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute top-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute bottom-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute bottom-0 left-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="sm:h-[2px] h-[1px] w-[10px] bg-white absolute bottom-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
              <span
                class="h-[10px] sm:w-[2px] w-[1px] bg-white absolute bottom-0 right-0 z-[41] transform transition-transform duration-300"
              ></span>
            </button>
          </li>
        </ol>
      </div>
      <div class="relative z-0 aspect-w-16 aspect-h-9 bg-main-color bg_box">
        <div
          class="absolute z-[1] top-0 left-0 right-0 bottom-0 bg-repeat opacity-20 mix-blend-multiply"
          style="
            background-image: url('/img/bg_texture.webp');
            background-size: 80px;
          "
        ></div>
        <svg
          class="absolute top-0 left-0 object-cover w-full h-full"
          viewBox="0 0 480 275"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="20"
            y="20"
            width="20"
            height="3"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="352"
            y="10"
            width="6"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect width="6" height="6" fill="#F6F6F6" fill-opacity="0.4" />
          <rect
            x="23"
            y="138"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="23"
            y="132"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="427"
            y="122"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="400"
            y="94"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="400"
            y="122"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="352"
            y="94"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="64"
            y="200"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="69"
            y="200"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="352"
            y="206"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="427"
            y="206"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="400"
            y="206"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="162"
            y="200"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="23"
            y="217"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="352"
            y="234"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="427"
            y="234"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="64"
            y="217"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="400"
            y="234"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="162"
            y="217"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="23"
            y="273"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="64"
            y="273"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="105"
            y="273"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="23"
            y="262"
            width="6"
            height="6"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="64"
            y="262"
            width="6"
            height="6"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="352"
            y="262"
            width="6"
            height="6"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="427"
            y="262"
            width="6"
            height="6"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="105"
            y="262"
            width="6"
            height="6"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="29"
            y="138"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="29"
            y="132"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="427"
            y="100"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="427"
            y="94"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="433"
            y="100"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="433"
            y="94"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="358"
            y="206"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="432"
            y="206"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="23"
            y="200"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="29"
            y="200"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="405"
            y="206"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="110"
            y="200"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="62"
            y="10"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="162"
            y="10"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="262"
            y="10"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="362"
            y="10"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="478"
            y="10"
            width="2"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="64"
            y="177"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="105"
            y="177"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="353"
            y="177"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="462"
            y="178"
            width="6"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="23"
            y="249"
            width="46"
            height="1"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="62"
            y="160"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="105"
            y="160"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="352"
            y="160"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
          <rect
            x="400"
            y="249"
            width="7"
            height="2"
            fill="#F6F6F6"
            fill-opacity="0.4"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { dialog } from '@/gsap/main_dialog';
import close_icon from '@/components/svg/close_icon.vue';
export default {
  name: 'LanguageDialog',
  data() {
    return {
      hover_close_btn: false,
      dialog_animation: null,
    };
  },
  components: {
    close_icon,
  },
  methods: {
    Close() {
      this.$store.commit('SetLanguageDialog', false);
    },
    ChooseLanguage(val) {
      if (val == 'en') {
        window.open('https://en.bikepow.com' + this.$route.fullPath);
      }
      this.Close();
    },
  },
  watch: {
    dialog() {
      this.dialog
        ? this.dialog_animation.open()
        : this.dialog_animation.close();
    },
  },
  computed: {
    dialog() {
      return this.$store.state.language_dialog;
    },
  },
  mounted() {
    this.dialog_animation = new dialog(this.$refs.MainContent);
  },
};
</script>

<style>
#MainDialog .main_content {
  -webkit-clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
}
</style>
