<template>
  <section
    id="HomeAbout"
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl px-10 pt-32 pb-48 mx-auto lg:px-40 sm:px-20"
  >
    <div class="relative w-full header">
      <div class="relative z-10">
        <h2
          data-title
          class="opacity font-audiowide md:text-8xl sm:text-[80px] text-[55px] leading-tight text-white sm:mb-9 mb-4"
        >
          ABOUT US
        </h2>
        <title_label text="關於我們" :light="false" />
      </div>

      <img
        data-header-image
        class="absolute right-0 z-0 w-2/3 transform header_img md:top-1/2 top-16 md:-translate-y-1/2 md:w-1/2"
        alt="關於野蠻公牛"
        src="/img/home/home_about_bg@lg.webp"
      />
    </div>
    <div
      data-cotnent
      class="w-full mt-20 ml-auto text-sm font-light leading-loose tracking-wider text-white content sm:text-base lg:w-1/2 md:w-2/3 sm:leading-loose sm:tracking-wider"
      v-html="page_data.content"
    ></div>

    <svg
      class="absolute block w-9 sm:left-36 left-20 top-12 md:hidden"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37.59 59.93"
    >
      <path
        class="cls-1 opacity-60"
        fill="#f6f6f6"
        d="M16.15,0H0L20.33,21H36.47Z"
        transform="translate(0 0)"
      />
      <path
        class="cls-1 opacity-60"
        fill="#f6f6f6"
        d="M25.56,47.43H16l12,12.5h9.56Z"
        transform="translate(0 0)"
      />
    </svg>

    <svg
      class="absolute block w-10 sm:right-44 right-32 bottom-12 md:hidden"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62 118"
    >
      <path
        class="cls-2 opacity-60"
        fill="#f6f6f6"
        d="M-59.39,69.16H-82.46l29,30h23.06Z"
        transform="translate(82.46 18.84)"
      />
      <path
        class="cls-3 opacity-60"
        fill="#f6f6f6"
        d="M-20.46.13v-19L-45.46,5V24Z"
        transform="translate(82.46 18.84)"
      />
    </svg>

    <button
      class="absolute bottom-0 left-0 flex items-center text-white transform rotate-90 font-audiowide lg:left-36 md:left-18 sm:left-12"
    >
      Scroll
      <arrow_forward class="text-2xl" />
    </button>
  </section>
</template>

<script>
import arrow_forward from '@/components/svg/arrow_forward';
import title_label from '@/components/ui/title_label.vue';
import { home_about_animation } from '@/gsap/home/about';
export default {
  name: 'HomeAbout',
  props: {
    page_data: {
      type: Object,
    },
  },
  components: {
    title_label,
    arrow_forward,
  },
  data() {
    return {
      home_about_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.home_about_animation = new home_about_animation(
        this.$refs.MainContent
      );
    },
  },
};
</script>
